.font-10 {
	font-size: 10px;
}

.font-11 {
	font-size: 11px;
}

.font-12 {
	font-size: 12px;
}

.font-13 {
	font-size: 13px;
}


.modal.modal-fullscreen .modal-dialog {
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	max-width: none;
}

.modal.modal-fullscreen .modal-content {
	height: auto;
	height: 100vh;
	border-radius: 0;
	border: none;
}

.modal.modal-fullscreen .modal-body {
	overflow-y: auto;
}

.textLayer {
	display: none;
}

.bg-login-image {

	background-position: 5% 50%;
	background-size: cover;
}

.cursor-pointer {
	cursor: pointer;
}